/**
 * Class
 * ============================================================================
 */
class Item {

	/* ======================================================================= *
	* Constructor
	* ======================================================================= */
	constructor(el, { clickCb }) {
		this.el = el;
		this.clickCb = clickCb;

		this.initEvents();
	}

	// Inits
	// -------------------------------------------------------------------------
	initEvents() {
		this.el.addEventListener("click", () => this.handleClick());
	}

	// Handlers
	// -------------------------------------------------------------------------
	handleClick() {
		if (this.clickCb) {
			this.clickCb();
		}
	}

}

export default Item;
