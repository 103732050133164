// =============================================================================
// Imports
// =============================================================================
import { Toggle } from "./../../extensions";
import { EventEmitter } from "./../../managers";
import { breakpoints } from "./../../globals";
import Button from "./Button";

// =============================================================================
// Definition
// =============================================================================
class Header extends Toggle {

	// Constructor
	// -------------------------------------------------------------------------
	constructor(el) {
		super({
			el,
			toggleClass: "is-open",
		});

		this.initBinds();
		this.init();
		this.initEvents();
	}

	// Inits
	// -------------------------------------------------------------------------
	init() {
		this.button = new Button(this.el.querySelector(".c-header__button"), {
			clickCb: this.handleDisableOverlay,
		});
	}

	initEvents() {
		// catch event and init function
		EventEmitter.on("js-header:toggle-mobile", () => super.toggle());

		EventEmitter.on("js-nav:handleClose", () => this.handleDisableOverlay());

		// closing open mobile nav when resizing window past breakpoint
		window.addEventListener("resize", this.handleCloseOverlayOnResize);
	}

	initBinds() {
		this.handleCloseOverlayOnResize = this.handleCloseOverlayOnResize.bind(this);
		this.handleDisableOverlay = this.handleDisableOverlay.bind(this);
	}

	// Handlers
	// -------------------------------------------------------------------------
	handleCloseOverlayOnResize() {
		const windowWidth = window.innerWidth;

		if (windowWidth > breakpoints["viewport-9"].width) {
			this.handleDisableOverlay();
		}
	}

	handleDisableOverlay() {
		if (!this.isToggled) {
			return;
		}

		super.disable();
		EventEmitter.emit("js-hamburger:handleClose");
		EventEmitter.emit("js-header-mobile:disabled");
	}
}

export default Header;
