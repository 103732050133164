// =============================================================================
// Definition
// =============================================================================

/**
 * Info
 * -----
 * Contains a name for each breakpoint and the width representing it. This
 * should be similar to the SCSS breakpoints config.
 */

export default {
	"viewport-7": {
		width: 720,
	},
	"viewport-9": {
		width: 992,
	},
};
