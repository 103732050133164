/* eslint-disable */
"use strict";

/**
 * Imports
 * =============================================================================
 */

import {
	Gallery,
	Hamburger,
	Header,
	Map,
	Navigation,
} from "./modules";

import { dom } from "./utils";

/**
 * Main initialise
 * initialise the different components for the general styleguide
 * =============================================================================
 */

/*
* These functions will wait until the dom is ready
*/
const init = () => {

	dom.init({
		selector: ".js-gallery",
		Module: Gallery,
	});

	dom.init({
		selector: ".js-hamburger",
		Module: Hamburger,
	});

	dom.init({
		selector: ".js-header",
		Module: Header,
	});

	dom.init({
		selector: ".js-map",
		Module: Map,
	});

	dom.init({
		selector: ".js-navigation",
		Module: Navigation,
	});
};

document.addEventListener("DOMContentLoaded", () => {
	if (document.readyState === "interactive") {
		init();
	}
});
