// =============================================================================
// Imports
// =============================================================================
import { EventEmitter } from "../../managers";
import Item from "./Item";

/**
 * Class
 * ============================================================================
 */
class Navigation {

	/* ======================================================================= *
	* Constructor
	* ======================================================================= */
	constructor(el) {
		this.el = el;

		this.initBinds();
		this.init();
	}

	// Inits
	// -------------------------------------------------------------------------
	init() {
		this.items = this.initItems();
	}

	initBinds() {
		this.handleClickItem = this.handleClickItem.bind(this);
	}

	initItems() {
		const els = this.el.querySelectorAll(".c-nav__link");
		const result = [];

		for (let i = 0; i < els.length; i++) {
			const el = els[i];

			result.push(new Item(el, {
				clickCb: this.handleClickItem,
			}));
		}

		return result;
	}

	// Handlers
	// -------------------------------------------------------------------------
	handleClickItem() {
		EventEmitter.emit("js-nav:handleClose");
	}
}

export default Navigation;
