/**
 * Class
 * ============================================================================
 */
class Button {

	/* ======================================================================= *
	* Constructor
	* ======================================================================= */
	constructor(el, { clickCb }) {
		this.el = el;
		this.clickCb = clickCb;

		this.initBinds();
		this.initEvents();
	}

	// Inits
	// -------------------------------------------------------------------------
	initBinds() {
		this.handleClick = this.handleClick.bind(this);
	}

	initEvents() {
		this.el.addEventListener("click", () => this.handleClick());
	}

	// Handlers
	// -------------------------------------------------------------------------
	handleClick() {
		if (this.clickCb) {
			this.clickCb();
		}
	}
}

export default Button;
