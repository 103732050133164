// =============================================================================
// Imports
// =============================================================================
import Item from "./Item";

// =============================================================================
// Definition
// =============================================================================
class Gallery {

	// Constructor
	// -------------------------------------------------------------------------
	constructor(el) {
		this.el = el;

		this.initBinds();
		this.init();
		this.initEvents();
	}

	// Inits
	// -------------------------------------------------------------------------
	init() {
		this.active = 0;
		this.items = this.initItems();
	}

	initBinds() {
		this.handlePrev = this.handlePrev.bind(this);
		this.handleNext = this.handleNext.bind(this);
		this.handleClickItem = this.handleClickItem.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
	}

	initEvents() {
		document.addEventListener("keydown", this.handleKeyDown);
	}

	initItems() {
		const els = this.el.querySelectorAll(".c-gallery__item");
		const result = [];

		for (let i = 0; i < els.length; i++) {
			const el = els[i];

			result.push(new Item(el, {
				index: i,
				prevCb: this.handlePrev,
				nextCb: this.handleNext,
				clickCb: this.handleClickItem,
			}));
		}

		return result;
	}

	// Handlers
	// -------------------------------------------------------------------------
	handlePrev() {
		this.items[this.active].disable();

		this.active = this.active <= 0
			? this.items.length - 1
			: this.active - 1;

		this.items[this.active].enable();
	}

	handleNext() {
		this.items[this.active].disable();

		this.active = this.active >= this.items.length - 1
			? 0
			: this.active + 1;

		this.items[this.active].enable();
	}

	handleClickItem(index) {
		this.active = index;
	}

	handleKeyDown(e) {
		const esc = 27;
		const left = 37;
		const right = 39;

		if (e.keyCode === left) {
			this.handlePrev();
		} else if (e.keyCode === right) {
			this.handleNext();
		} else if (e.keyCode === esc) {
			this.items[this.active].handleClose();
		}
	}
}

export default Gallery;
