// =============================================================================
// Imports
// =============================================================================
import EventEmitter from "./EventEmitter";

let instance = null;

// =============================================================================
// Definition
// =============================================================================
class Root {

	// Constructor
	// -------------------------------------------------------------------------
	constructor() {
		if (!instance) {
			instance = this;
		}

		this.init();
		this.initEvents();

		return instance;
	}

	// Inits
	// -------------------------------------------------------------------------
	init() {
		this.el = document.documentElement;
	}

	initEvents() {
		EventEmitter.on("js-header-mobile:disabled", () => this.enableScroll());
		EventEmitter.on("js-header:toggle-mobile", () => this.toggleScroll());
	}

	// Handlers
	// -------------------------------------------------------------------------
	toggleScroll() {
		if (this.el.classList.contains("has-scroll-disabled")) {
			this.enableScroll();
		} else {
			this.disableScroll();
		}
	}

	disableScroll() {
		this.el.classList.add("has-scroll-disabled");
	}

	enableScroll() {
		this.el.classList.remove("has-scroll-disabled");
	}
}

export default new Root;
