// =============================================================================
// Imports
// =============================================================================
import { Toggle } from "./../../extensions";
import { Root } from "./../../managers";

// =============================================================================
// Definition
// =============================================================================
class Item extends Toggle {

	constructor(el, {
		index,
		prevCb = () => null,
		nextCb = () => null,
		clickCb = () => null,
	}) {
		super({
			el,
			toggleClass: "is-open",
		});

		this.el = el;
		this.prevCb = prevCb;
		this.nextCb = nextCb;
		this.clickCb = clickCb;
		this.index = index;

		this.initBinds();
		this.init();
		this.initEvents();
	}

	// Inits
	// -------------------------------------------------------------------------
	initBinds() {
		this.handlePrev = this.handlePrev.bind(this);
		this.handleNext = this.handleNext.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	init() {
		this.image = this.el.querySelector(".c-gallery__visual-container");
		this.prev = this.el.querySelector(".c-overlay__arrow-prev");
		this.next = this.el.querySelector(".c-overlay__arrow-next");
		this.close = this.el.querySelector(".c-overlay__button");
	}

	initEvents() {
		this.prev.addEventListener("click", this.handlePrev);
		this.next.addEventListener("click", this.handleNext);
		this.image.addEventListener("click", this.handleClick);
		this.close.addEventListener("click", this.handleClose);
	}

	// Handlers
	// -------------------------------------------------------------------------
	handlePrev(e) {
		if (e) {
			e.preventDefault();
		}

		this.prevCb();
	}

	handleNext(e) {
		if (e) {
			e.preventDefault();
		}

		this.nextCb();
	}

	handleClick() {
		this.enable();

		this.clickCb(this.index);

		Root.disableScroll();
	}

	handleClose() {
		this.disable();

		Root.enableScroll();
	}
}

export default Item;
